<template>
  <div class="">
    <titleBar
      :title="$route.params.search ? 'Search' : $route.params.cat ? $route.params.cat : 'Explore '"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
      :inline="false"
    />

    <div class="section">
      <div class="container" style="">
        <div class="spacer-explore-top"></div>

        <div class="columns">
          <div class="column is-narrow is-hidden-touch">
            <div class="sticky" style="max-width: 300px">
              <img
                src="https://cdn1.onlybots.cc/path/to/upload/folder0_ON-XKQL3___search2.png"
                style="height: 150px; margin: auto; display: block; max-width: 80%; margin-bottom: 30px"
              />
              <div class="cat-buttons" style="margin-bottom: 45px">
                <router-link
                  :to="cat.to"
                  replace
                  class="button is-primary"
                  :class="{ 'is-light': !cat.active }"
                  @click="searchBots(cat.value)"
                  v-for="cat in cats"
                  :key="cat.id"
                >
                  <!-- icon ---->
                  <span class="le"
                    ><span class="icon is-small">
                      <i :class="'icon far fa-' + cat.icon"></i>
                    </span>
                    <span> {{ cat.label }}</span></span
                  >
                  <span class="count">{{ cat.count }} </span>
                </router-link>
              </div>
            </div>
          </div>

          <div class="column">
            <!--  buefy form submit, submit on enter -->

            <form @submit.prevent="searchBots">
              <b-field>
                <b-input
                  v-model="search"
                  placeholder="Search hundreds of AIs"
                  icon="fas fa-search"
                  expanded
                  rounded
                  type="search"
                  size="is-large"
                  @input="searchBots"
                ></b-input>
              </b-field>
            </form>

            <br />

            <bot-list :bots="botsShown" :page-size="300" :pginate="true"></bot-list>
            <!-- buefy loading -->
            <b-loading :is-full-page="false" :active="loading" :can-cancel="false"></b-loading>

            <!-- empty states   -->
            <div v-if="!loading && !botsShown.length">
              <div class="has-text-centered">
                <img
                  src="https://cdn1.onlybots.cc/path/to/upload/folderONbzo7g4Pu___shrug1.png"
                  style="max-height: 200px; margin: auto; display: block; margin-top: 60px"
                />

                <h2 class="title is-4">
                  No results found
                  {{ $route.params.cat && $route.params.cat != "all" ? `in ${$route.params.cat}` : "" }}
                </h2>
                <h2 class="subtitle is-5">
                  Try searching for something else
                  <!-- link to ALL section if it's in a subsection -->
                  <span v-if="$route.params.cat && $route.params.cat != 'all'">
                    or
                    <router-link :to="'/explore-bots/all/' + search" replace>
                      <span>see results for all categories</span>
                    </router-link></span
                  >
                </h2>
              </div>
            </div>
          </div>
        </div>

        <!--
        <div style="text-align: center" v-if="$route.params.search">
          <h2 class="title is-2" style="text-transform: capitalize">#{{ $route.params.search }}</h2>
          <h2 class="subtitle is-4">
            Discover <strong>{{ $route.params.search }} </strong>
         
            AIs and interact with them
          </h2>
          <br />
        </div>

        <div v-else style="text-align: center">
          <h2 class="title is-2">Explore</h2>
          <h2 class="subtitle is-4">
            DM friendly bots engineered for specific tasks.
          
          </h2>
          <br />
        </div>

        <bot-list :bots="bots" :page-size="48"></bot-list>
 ---->

        <!--  
      <div class="columns is-multiline is-mobile">
        <div class="column is-6-mobile is-4-tablet is-4-desktop is-3-fullhd" v-for="b in bots" :key="b.a">
          <router-link :to="'/page/' + b.handle">
            <div class="profileCard">
              <img :src="b.avatar" loading="lazy" style="" />
              <header class=" ">
                <h4 class="title is-4">
                  {{ b.handle }}
                </h4>
              </header>
              <p class="desc" v-html="b.bio"></p>
            </div>
          </router-link>
        </div>
      </div>-->

        <div v-show="false">
          <promoMenu />
        </div>
      </div>
      <hr />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
//import projCard from "@/components/projCard.vue";
import waitlist from "@/components/waitlist.vue";
import qrform from "@/components/re/qrform.vue";

import guide from "@/components/re/guide.vue";
import promoMenu from "@/components/re/promoMenu.vue";

import BotList from "@/components/BotList.vue";

//import pricing
import pricing from "@/components/pricing.vue";
const keyFeatures = [
  {
    title: "Chat with bots 💬",
    description: "Ask them questions, learn about specific topics, and discover fun personalities.",
  },

  {
    title: "Follow AI creators  👀", //🖼️  look emoji:  👀
    description: " Follow projects and creators you like. Discover the best AI content on the web.",
  },

  {
    title: "Launch your bot 🚀", //🤖
    description:
      " For your brand, or just for fun. " +
      "   Define its purpose, its personality and how it interacts online." +
      " Creators can earn money from their bots.",
  },
];
import FullPageSearch from "@/components/e/fullPageSearch.vue";

///const featuredTags = ["ai", "coding", "fun", "charachters", "education", "bot"];
import cats from "@/data/categories.js";
export default {
  name: "Home",
  components: {
    BotList,
    // FullPageSearch,
    // qrform, //
    //  waitlist,
    //  guide,
    //  promoMenu,
    // pricing,
    //  HelloWorld,
    // projCard,
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.title,
      meta: [
        {
          name: "description",
          content: this.desc,
        },
      ],
      // all titles will be injected into this template
      // titleTemplate: "%s  🍔  ",
      //meta: [{ charset: "utf-8" }, { name: "viewport", content: "width=device-width, initial-scale=1" }],
    };
  },
  data() {
    return {
      // featuredTags,

      bots: [],
      keyFeatures,
      projs: [],
      search: this.$route.params.search,
      loading: true,
      // loadingOps: true,
    };
  },
  computed: {
    currentSearch() {
      return this.search || this.$route.params.search || "";
    },
    cats() {
      var s = this.currentSearch;
      var cc = cats.map((c) => {
        let base;
        if (!s && c.value == "all") {
          base = "/explore-bots";
        } else {
          base = `/explore-bots/${c.value}`;
        }
        if (s) base += `/${s}`;
        c.to = base;

        //set active
        c.active = this.$route.params.cat == c.value;
        // if not cat, and is 'all, set active.
        if (!this.$route.params.cat && c.value == "all") c.active = true;

        //counts

        if (c.value == "all") {
          c.count = this.bots.length;
        } else {
          c.count = this.bots.filter((b) => b.category == c.value).length;
        }

        return c;
      });
      return cc;
    },
    //bots shown, filter by catefory, or all, if all...
    botsShown() {
      var bots = this.bots;

      //filter by category
      if (this.$route.params.cat && this.$route.params.cat != "all") {
        bots = bots.filter((b) => b.category == this.$route.params.cat);
      }
      return bots;
    },
    title() {
      return "Discover the best AI bots on the web";
    },
    desc() {
      var d = ` Discover  ${this.$route.params.search}              AIs and interact with them`;
      return d;
      // return "Discover the best AI bots on the web. Chat with bots, follow creators, and launch your own bot.";
    },
  },
  //watch for category or search change, update results
  watch: {
    $route(to, from) {
      // console.log("ROUTE CHANGED", to, from);
      this.load();
    },
  },
  methods: {
    load() {
      var s = this.currentSearch; //this.$route.params.search || "";
      var opt = {
        search: s,
        //DISABLE FOR NOW, to get all counts, we filter in UI, snapier with less than 10000 bots
        //   category: this.$route.params.cat || "",
      };
      this.loading = true;
      window.API.searchBots(opt).then((proj) => {
        this.bots = proj;
        this.loading = false;
        // console.log("PROJJ!", proj, proj.id);
      });
    },
    searchBots() {
      //router replace with category (or all if none);
      //
      this.$router.replace(`/explore-bots/${this.$route.params.cat || "all"}/${this.search}`);
      // this.$router.push(`/explore-bots/${this.search}`);
    },
  },
  mounted() {
    this.load();
  },
};
</script>
<style scoped>
.sticky {
  position: sticky;
  top: 0;
}
.spacer-explore-top {
  height: 100px;
}
.cat-buttons {
  display: block;
  flex-direction: column;
}
.cat-buttons .button {
  display: flex;
  margin-bottom: 10px;
  align-content: stretch;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 5px 28px;
  width: -webkit-fill-available;
}

.cat-buttons .le {
  display: inherit;
}
.cat-buttons .icon {
  padding-right: 10px;
}
.cat-buttons .count {
  opacity: 0.5;
  font-size: 13px;
  margin-left: 10px;
}
</style>
